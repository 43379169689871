<template>
  <el-upload class="upload" action :multiple="false" :show-file-list="false"
    accept="csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    :http-request="httpRequest">
    <el-button type="primary" :plain="isPlain">Excel上传</el-button>
  </el-upload>
</template>

<script>
  import XLSX from "xlsx" //引入xlsx
  export default {
    data() {
      return {}
    },
    props:{
      isPlain: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      httpRequest(e) {
        let file = e.file // 文件信息
        if (!file) {
          // 没有文件
          return false
        } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
          // 格式根据自己需求定义
          this.$message.error("上传格式不正确，请上传xls或者xlsx格式")
          return false
        }
        const fileReader = new FileReader()
        fileReader.onload = ev => {
          try {
            const data = ev.target.result
            const workbook = XLSX.read(data, {
              type: "binary", // 以字符编码的方式解析
              cellDates: true
            });
            const exlname = workbook.SheetNames[0] // 取第一张表
            let outdata = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]) // 生成json表格内容
            // 自定义方法向父组件传递数据
            this.$emit('upData', outdata)
          } catch (e) {
            this.$message.error("出错了")
            return false
          }
        };
        fileReader.readAsBinaryString(file)
      }
    }
  }
</script>

<style>
</style>
